var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"term-item bg-grey-gradient",attrs:{"loading":_vm.loading,"dark":"","ripple":false},on:{"click":function($event){return _vm.$emit('click', _vm.value)},"mouseover":() => _vm.$emit('mouseover', _vm.value),"mouseleave":() => _vm.$emit('mouseleave', _vm.value)}},[_c('div',{staticClass:"item-active-indicator",class:{ active: _vm.active }}),_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),(_vm.value.status)?_c('div',{staticClass:"term-status",class:{
      primary: _vm.value.status.code === 'PUBLISHED',
      'teal darken-1': _vm.value.status.code === 'PENDING',
    }},[_vm._v(" "+_vm._s(_vm.value.status.name)+" ")]):_vm._e(),_c('div',[_c('v-card-title',{staticClass:"term-title"},[_vm._v(" "+_vm._s(_vm.value.name)+" ")]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{staticClass:"flex-column",class:{
            'example-col':
              _vm.value.publicMaterials && _vm.value.publicMaterials.length,
          },attrs:{"cols":"4"}},[(_vm.value.publicMaterials && _vm.value.publicMaterials.length)?_c('v-carousel',{attrs:{"hide-delimiter-background":"","hide-delimiters":"","show-arrows-on-hover":"","height":"140"},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c('div',{staticClass:"direction-wrapper-light"},[_c('v-icon',_vm._g(_vm._b({staticClass:"direction-icon",attrs:{"color":"black"}},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function({ on, attrs }){return [_c('div',{staticClass:"direction-wrapper-light"},[_c('v-icon',_vm._g(_vm._b({staticClass:"direction-icon",attrs:{"color":"black"}},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-right")])],1)]}}],null,false,2756797828)},_vm._l((_vm.value.publicMaterials),function(material,i){return _c('v-carousel-item',{key:i,attrs:{"contain":"","src":material.url}})}),1):_vm._e(),_c('v-rating',{attrs:{"value":4.5,"color":"amber","dense":"","half-increments":"","readonly":"","size":"14"}}),_c('div',{staticClass:"grey--text ms-4"},[_vm._v("4.5 (413)")])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('h3',{staticClass:"brief"},[_vm._v("BRIEF:")]),_c('p',{staticClass:"simple-text deliverable-description"},[_vm._v(" "+_vm._s(_vm.cutFormatter(_vm.value.brief, 110))+" ")]),_c('h3',{staticClass:"brief"},[_vm._v("WHAT HAS BEEN DONE:")]),_c('p',{staticClass:"simple-text"},[_vm._v(" "+_vm._s(_vm.cutFormatter(_vm.value.what_has_been_done, 110))+" ")])])],1)],1),(_vm.value.tags && _vm.value.tags.length)?_c('v-card-actions',{staticClass:"card-actions-bottom"},_vm._l((_vm.value.tags),function(item){return _c('v-chip',{key:item.tag.id,staticClass:"ma-2",attrs:{"color":item.tag.color,"label":"","text-color":"white"}},[(item.tag.icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.tag.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.tag.name)+" ")],1)}),1):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
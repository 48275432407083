<template>
  <AView
    :value="value"
    :hasFilters="
      !!(filter.search || (filter.serviceIds && filter.serviceIds.length))
    "
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    :payload="{
      from: value,
    }"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            :label="'Case Studies'"
            :icon="value.view.display.icon"
            :actions="actions"
            :payload="{
              from: value,
            }"
            right
          ></FormSection>
        </v-col>
      </v-row>

      <v-row class="scroll-row d-flex flex-grow-1">
        <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
          <v-virtual-scroll
            class="pa-0 ma-0"
            :bench="5"
            :items="caseStudies"
            height="630"
            item-height="360"
            @scroll="onScroll"
          >
            <template v-slot:default="{ item }">
              <CaseStudyItemVue
                class="ma-2"
                :value="item"
                :style="{
                  height: '350px',
                }"
                :active="activeCaseStudyId === item.id"
                :key="item.id"
                @onOpen="onSelect"
                @click="onSelect"
                @mouseover="onHover"
                @mouseleave="() => {}"
              ></CaseStudyItemVue>
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>

      <Pagination
        :value="pagination"
        :show="showMore"
        :total="caseStudies.length"
        @onLoadMore="
          $store.dispatch('CaseStudyStore/GetCaseStudiesList', {
            next: true,
          })
        "
      ></Pagination>
    </template>

    <template v-slot:actions="{}">
      <CaseStudyFilter v-model="filter"> </CaseStudyFilter>
    </template>
  </AView>
</template>
    
    
    
<script>
import { mapState } from "vuex";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import Pagination from "../../../../../../components/wad/atoms/common/Pagination.vue";
import CaseStudyFilter from "../../../../../../components/wad/molecules/knowledgeBase/caseStudies/CaseStudyFilter.vue";
import CaseStudyItemVue from "../../../../../../components/wad/organisms/knowledgeBase/caseStudies/CaseStudyItem.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { CASE_STUDY_BUTTONS } from "../../constants/actions.gh";

export default {
  name: "CaseStudies",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    CaseStudyItemVue,
    CaseStudyFilter,
    Pagination,
  },
  computed: {
    ...mapState("CaseStudyStore", [
      "caseStudies",
      "activeCaseStudyId",
      "pagination",
    ]),
    actions: function () {
      return PermissionsHelper.getActions(
        CASE_STUDY_BUTTONS.CREATE_NEW,
        this.value.permissions
      );
    },
  },
  created() {
    this.$store.dispatch("CaseStudyStore/GetCaseStudiesList", {
      reset: true,
    });
  },
  data() {
    return {
      filter: {},
      showMore: false,
    };
  },
  methods: {
    onHover(value) {
      this.$store.commit("CaseStudyStore/setActiveId", value.id);
    },
    onSelect(caseStudy) {
      this.$store.dispatch("CaseStudyStore/SetActiveCaseStudy", {
        from: this.value,
        caseStudy,
      });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("END");
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        this.$store.dispatch("CaseStudyStore/GetCaseStudiesList", {
          filter: newVal,
          reset: true,
        });
      },
      deep: true,
    },
  },
};
</script>
    
    
    
<style scoped lang="scss" >
.scroll-row {
  height: calc(100% - 110px);
}
</style>
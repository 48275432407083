<template>
  <v-card
    :loading="loading"
    class="term-item bg-grey-gradient"
    dark
    :ripple="false"
    @click="$emit('click', value)"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>

    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <div
      v-if="value.status"
      class="term-status"
      :class="{
        primary: value.status.code === 'PUBLISHED',
        'teal darken-1': value.status.code === 'PENDING',
      }"
    >
      {{ value.status.name }}
    </div>
    <div>
      <v-card-title class="term-title">
        {{ value.name }}
      </v-card-title>
      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <v-row class="justify-space-between">
          <v-col
            cols="4"
            class="flex-column"
            :class="{
              'example-col':
                value.publicMaterials && value.publicMaterials.length,
            }"
          >
            <v-carousel
              hide-delimiter-background
              hide-delimiters
              show-arrows-on-hover
              v-if="value.publicMaterials && value.publicMaterials.length"
              height="140"
            >
              <template v-slot:prev="{ on, attrs }">
                <div class="direction-wrapper-light">
                  <v-icon
                    class="direction-icon"
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-chevron-left</v-icon
                  >
                </div>
              </template>
              <template v-slot:next="{ on, attrs }">
                <div class="direction-wrapper-light">
                  <v-icon
                    class="direction-icon"
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-chevron-right</v-icon
                  >
                </div>
              </template>
              <v-carousel-item
                contain
                v-for="(material, i) in value.publicMaterials"
                :key="i"
                :src="material.url"
              >
              </v-carousel-item>
            </v-carousel>
            <v-rating
              :value="4.5"
              color="amber"
              dense
              half-increments
              readonly
              size="14"
            ></v-rating>
            <div class="grey--text ms-4">4.5 (413)</div>
          </v-col>
          <v-col cols="8">
            <h3 class="brief">BRIEF:</h3>
            <p class="simple-text deliverable-description">
              {{ cutFormatter(value.brief, 110) }}
            </p>
            <h3 class="brief">WHAT HAS BEEN DONE:</h3>
            <p class="simple-text">
              {{ cutFormatter(value.what_has_been_done, 110) }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="card-actions-bottom"
        v-if="value.tags && value.tags.length"
      >
        <v-chip
          class="ma-2"
          v-for="item in value.tags"
          :key="item.tag.id"
          :color="item.tag.color"
          label
          text-color="white"
        >
          <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
          {{ item.tag.name }}
        </v-chip>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { FormatterHelper } from "../../../../helpers/formatter.helper";
// import { CASE_STUDY_PERMISSIONS } from "../../../../../views/src/apps/knowledge-base/constants/permissions.gh";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => {
    return {
      cutFormatter: FormatterHelper.cutString,

      // CASE_STUDY_PERMISSIONS,
      loading: false,
      show: false,
      ripple:true
    };
  },
  components: {},
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
   
  },
};
</script>


<style lang="scss" scoped>
.term-item {
  z-index: 2;
  overflow: hidden;

  .term-title {
    text-transform: uppercase;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .term-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }
  .example-col {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
</style>